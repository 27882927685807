import React, {useEffect} from "react";
import github from "../../../backend/github";
import Loader from "../../../components/Core/Loader";
import PrivateRoute from "../../../router/PrivateRoute";

const AuthenticatePage = () => {
  useEffect(() => {
    github.authenticateApp()
      .then(({data}) => {
        const { url } = data;

        if (typeof url === "undefined") {
          alert("Error! Please try again.");

        } else {
          window.location.href = url;
        }
      });
  }, []);

  return (
    <Loader/>
  );
};

function authenticate(props) {
  return <PrivateRoute component={AuthenticatePage} {...props}/>
}

export default authenticate;
